@tailwind base;
@tailwind components;
@tailwind utilities;

.skill-icon {
    margin-right: 1rem; /* Ruimte tussen de icoontjes */
}

/* styles.css */
.skills-section {
    margin-left: 18rem;
}

.profielfoto {
    margin-left: 18rem;
}

.text-center {
    text-align: center;
}

.typing-animation {
    overflow: hidden; /* Verberg overlopende tekst */
    white-space: nowrap; /* Voorkom dat de tekst naar een nieuwe regel gaat */
    margin: 0 auto; /* Centreer de tekst */
    animation: typing 7.5s steps(40, end); /* Pas de animatie toe */
}

.carousel {
    overflow: hidden; /* Verberg overlopende inhoud */
    /* Verwijder white-space en overflow eigenschappen */
}

.carousel img {
    display: inline-block;
    width: 10%; /* Pas de breedte van de afbeeldingen aan */
    height: 50px; /* Houd de hoogte hetzelfde als voorheen */
    margin: 0 10px; /* Adjust margin as needed */
    transition: transform 1s ease-in-out;
}

.carousel img:hover {
    transform: scale(1.25); /* Adjust scale factor for hover effect */
}

@keyframes slide {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-100% + 20px)); } /* Aanpassen aan de totale breedte van de afbeeldingen en marge */
}

.carousel {
    animation: slide 10s alternate-reverse infinite; /* Pas de duur van de animatie aan zoals gewenst */
}

.section {
    height: 100vh; /* Maakt de sectie schermvullend */
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start; /* Zorgt ervoor dat de sectie op het scherm blijft plakken tijdens scrollen */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Voeg schaduw toe */
}

.fixed-height {
    height: 315px; /* Or any other fixed height you prefer */
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.carousel2, .carousel3, .carousel4 {
    position: relative;
    width: 100%;
    height: 315px; /* Match this with the .fixed-height */
}

/* Additional custom styles for mobile responsiveness */
@media (max-width: 640px) {
    /* Move text above image in About Section */
    #about .flex {
        flex-direction: column;
    }
    #about .w-1\/2 { /* Escaping the slash character */
        width: 100%;
    }
    #about .w-1\/2:first-child {
        margin-bottom: 2rem;
    }

    #projects .flex {
        flex-direction: column;
    }

    /* Stel de breedte in op 100% om de kaarten de volledige breedte van de viewport te geven */
    #projects .w-full {
        width: 100%;
    }

    .carousel {
        overflow: visible; /* Zorg ervoor dat de inhoud van de carousel zichtbaar is */
        white-space: nowrap; /* Voorkom dat tekst naar een nieuwe regel gaat */
        margin: 0 auto; /* Centreer de carousel */
        width: 100%; /* Maak de carousel schermvullend */
    }

    .carousel img {
        width: auto; /* Stel de breedte in op automatisch om de oorspronkelijke grootte te behouden */
        height: 50px; /* Houd de hoogte hetzelfde als voorheen */
        margin: 0 10px; /* Aanpassen van de marge indien nodig */
        transition: none; /* Verwijder de hover-effect overgang */
    }

    /* Move text above image in Skills Section */
    #skills .flex {
        flex-direction: column;
    }
    #skills .w-1\/2 {
        width: 100%;
    }
    #skills .w-1\/2:first-child {
        margin-bottom: 2rem;
    }

    /* Center align text in Skills Section */
    /*#skills .text-center {*/
    /*    text-align: center;  */
    /*}*/

    /* Position image to the right */
    #skills .w-1\/2:last-child {
        margin-right: 0; /* Remove any right margin */
        vertical-align: middle;
    }

    /* Scale images in Sections */
    section img {
        max-width: 100%;
        height: auto;
    }

    /* Additional styles for Skills section */
    .skills-section {
        margin-left: 0; /* Remove the left margin on mobile devices */
        padding: 0 1rem; /* Add padding to the sides */
        text-align: center; /* Center the text on mobile devices */
    }

    .profielfoto {
        margin: 0 auto; /* Center the photo */
        display: block; /* Ensure the photo is above the text */
        margin-bottom: 1rem; /* Add some space below the photo */
    }

    .skill-icons-container {
        margin-top: 1rem; /* Add some space above the skill icons */
    }

    @media (max-width: 640px) {
        #projects .grid-cols-1 {
            grid-template-columns: 1fr; /* Zorg ervoor dat de grid één kolom heeft op kleine schermen */
        }
    }

    /* Verklein de lettergrootte van de welkomstsectie */
    #home h1.text-9xl {
        font-size: 5rem; /* Pas de grootte aan naar jouw voorkeur */
    }
    #home h2.text-5xl {
        font-size: 2rem; /* Pas de grootte aan naar jouw voorkeur */
    }
    #home p.text-xl {
        font-size: 1.5rem; /* Pas de grootte aan naar jouw voorkeur */
    }

}
